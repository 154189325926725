import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Collapse,
  Fade,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Image from "./Image";
import MyAvatar from "./MyAvatar";
import ReactMarkdown from "react-markdown";

const customScrollbar = {
  "&::-webkit-scrollbar": { width: "8px" },
  "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "10px",
    border: "2px solid #f1f1f1",
  },
  "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#555" },
};

const userBubbleStyle = {
  backgroundColor: "#dcf8c6",
  borderRadius: "16px",
  padding: "14px",
  textAlign: "left",
  maxWidth: "100%",
  display: "inline-block",
  position: "relative",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
};

const botBubbleStyle = {
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "14px",
  textAlign: "left",
  maxWidth: "100%",
  display: "inline-block",
  position: "relative",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  fontFamily: "Arial, sans-serif",
  fontSize: "14px",
  lineHeight: "1.6",
};

function ChatBot() {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const [showHelp, setShowHelp] = useState(true);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const sendMessage = async () => {
    if (!userInput) return;

    setMessages((prev) => [...prev, { type: "user", text: userInput }]);
    const query = userInput;
    setUserInput("");

    setMessages((prev) => [...prev, { type: "bot", text: "" }]);

    const formData = new FormData();
    formData.append("query", query);
    formData.append("user_id", "test_user");
    formData.append("agent_type", "omodore_web_support");

    try {
      const response = await fetch("http://155.130.70.168:4000/stream_chat/", {
        method: "POST",
        body: formData,
      });

      if (!response.body) {
        throw new Error("Streaming not supported in this browser.");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let done = false;
      let accumulatedText = "";

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunk = decoder.decode(value, { stream: true });
        accumulatedText += chunk;

        setMessages((prev) => {
          const updatedMessages = [...prev];
          updatedMessages[updatedMessages.length - 1] = {
            type: "bot",
            text: accumulatedText,
          };
          return updatedMessages;
        });
      }
    } catch (error) {
      console.error("Error streaming message:", error);
    }
  };

  const toggleChat = () => setChatOpen(!chatOpen);

  return (
    <Container maxWidth="sm" sx={{ marginTop: "50px" }}>
      {!chatOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000000,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography
            sx={{
              backgroundColor: "#fff",
              display: showHelp ? "inline-block" : "none",
              borderRadius: "12px 12px 0px 12px",
              p: 2,
              textAlign: "left",
              maxWidth: "90%",
              position: "relative",
              boxShadow: 3,
              mb: 2,
            }}
          >
            <IconButton
              onClick={() => setShowHelp(false)}
              sx={{
                position: "absolute",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                color: "black",
                top: "-10px",
                right: "-10px",
              }}
            >
              <CloseIcon />
            </IconButton>
            Hey! Need some help?
          </Typography>
          <Fade in={!chatOpen}>
            <IconButton
              color="primary"
              onClick={toggleChat}
              sx={{
                borderRadius: "50%",
                width: "65px",
                height: "65px",
                padding: "13px",
                backgroundColor: "#3EB54B",
                "&:hover": { backgroundColor: "#007B55" },
                color: "white",
              }}
            >
              <Image src="/assets/icons/chatbot.png" alt="AI Chat Bot" />
            </IconButton>
          </Fade>
        </Box>
      )}

      <Collapse in={chatOpen} unmountOnExit>
        <Box
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 10001,
            width: "350px",
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
            transition: "all 0.3s ease-in-out",
            transform: chatOpen ? "translateY(0)" : "translateY(100%)",
            borderRadius: "15px",
            height: "500px",
          }}
        >
          <Box
            sx={{
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              backgroundColor: "#3EB54B",
              color: "white",
              display: "flex",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Omodore
            </Typography>
            <IconButton
              color="inherit"
              onClick={toggleChat}
              sx={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              padding: 2,
              flexGrow: 1,
              overflowY: "auto",
              backgroundColor: "white",
              ...customScrollbar,
            }}
          >
            <List>
              {messages.map((message, index) => {
                const isUser = message.type === "user";
                return (
                  <ListItem
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: isUser ? "flex-end" : "flex-start",
                      mb: 1,
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: isUser ? "flex-end" : "flex-start",
                            }}
                          >
                            <Typography sx={isUser ? userBubbleStyle : botBubbleStyle}>
                              {isUser ? (
                                message.text
                              ) : (
                                <ReactMarkdown
                                  children={message.text}
                                  components={{
                                    a: ({ node, ...props }) => (
                                      <a
                                        {...props}
                                        style={{
                                          color: "#007B55",
                                          textDecoration: "underline",
                                        }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      />
                                    ),
                                    li: ({ node, ...props }) => (
                                      <li style={{ marginBottom: "8px" }} {...props} />
                                    ),
                                  }}
                                />
                              )}
                            </Typography>
                          </Box>
                          {isUser ? (
                            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                              <MyAvatar />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                borderRadius: "50%",
                                width: "42px",
                                height: "42px",
                                p: 1,
                                backgroundColor: "#3EB54B",
                                color: "white",
                                display: "flex",
                                mt: 1,
                              }}
                            >
                              <Image src="/assets/icons/chatbot.png" alt="AI Chat Bot" />
                            </Box>
                          )}
                        </Box>
                      }
                    />
                  </ListItem>
                );
              })}
              <div ref={messagesEndRef} />
            </List>
          </Box>

          <Box
            display="flex"
            p={2}
            gap={1}
            sx={{
              backgroundColor: "white",
              borderBottomRightRadius: "15px",
              borderBottomLeftRadius: "15px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Type a message..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && sendMessage()}
              sx={{
                bgcolor: "white",
                flexGrow: 1,
                borderRadius: "24px",
                "& .MuiOutlinedInput-root": { borderRadius: "24px" },
              }}
            />
            <IconButton
              color="primary"
              onClick={sendMessage}
              sx={{
                color: "white",
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                backgroundColor: "#3EB54B",
                "&:hover": { backgroundColor: "#007B55" },
              }}
            >
              <Image src="/assets/icons/send.png" alt="send icon" />
            </IconButton>
          </Box>
        </Box>
      </Collapse>
    </Container>
  );
}

export default ChatBot;
